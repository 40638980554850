<template>
    <v-app-bar id="nav" app>
      <v-app-bar-nav-icon @click.stop="$emit('drawer')" style="color:white"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="headline text-uppercase">
        <span class="font-weight-light d-none d-sm-inline">WorkCTE</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Área do cronômetro -->
      <div style="color:white; margin-right: 20px;">
        <span v-if="timeRemaining > 0">{{ formattedTime }}</span>
        <span v-else>Token expirado, Saia do sistema e entre novamente</span>
      </div>
    </v-app-bar>
  </template>
  
  <script>
  export default {
    name: 'Header',
    data() {
      return {
        timeRemaining: 0, // em segundos
        interval: null,
      };
    },
    computed: {
      // Converte o tempo restante para o formato mm:ss
      formattedTime() {
        const minutes = Math.floor(this.timeRemaining / 60);
        const seconds = this.timeRemaining % 60;
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      },
    },
    mounted() {
      // Recupera a data/hora de expiração do token do localStorage
      const storedExpiration = localStorage.getItem("tokenExpiration");
      if (storedExpiration) {
        const tokenExpiration = parseInt(storedExpiration, 10);
        const currentTime = Math.floor(Date.now() / 1000); // tempo atual em segundos
        const remaining = tokenExpiration - currentTime;
        // Se o token já expirou, define timeRemaining como 0
        this.timeRemaining = remaining > 0 ? remaining : 0;
      } else {
        // Se não existir, pode ser que o usuário não esteja logado
        this.timeRemaining = 0;
      }
      this.startTimer();
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    methods: {
      startTimer() {
        this.interval = setInterval(() => {
          if (this.timeRemaining > 0) {
            this.timeRemaining--;
          } else {
            clearInterval(this.interval);
          }
        }, 1000);
      },
    },
  };
  </script>
  
  <style scoped>
  .rounded-0 {
    border-radius: 0;
    color: red;
    font-size: 30px;
  }
  #nav {
    background-color: #1d4ed8;
    color: white;
  }
  </style>
  