export const menu = [
    { icon: 'mdi-home', title: 'Início', routeName: 'Monitor', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_USUARIO_OPERADOR'] },
    { icon: 'mdi-truck', title: 'Cadastro de Transportador', routeName: 'Tranportador', permissoes: ['ROLE_ADMIN', 'ROLE_MODERADOR'] },
    { icon: 'mdi-truck-fast', title: 'Emissão de Viagem', routeName: 'EmissaoViagem', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR'] },
    { icon: 'mdi-cached', title: 'Atualizar Certificado', routeName: 'AtualizaCertificado', permissoes: ['ROLE_ADMIN', 'ROLE_MODERADOR', 'ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR'] },
    { icon: 'mdi-chart-bar', title: 'Relatórios CT-e', routeName: 'CTE', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR'] },
    { icon: 'mdi-chart-line', title: 'Relatórios MDF-e', routeName: 'MDFE', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR'] },
    { icon: 'mdi-receipt', title: 'Relatórios Receber', routeName: 'Receber', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR'] },
    { icon: 'mdi-file-export', title: 'Exportar PDF/XML', routeName: 'Exportar', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_USUARIO_OPERADOR'] },
    { icon: 'mdi-microsoft-excel', title: 'Exportar XLSX', routeName: 'Xlsx', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_CONTADOR'] },
    { icon: 'mdi-stop-circle', title: 'Encerrar MDF-e', routeName: 'MDFEE', permissoes: ['ROLE_MOTORISTA', 'ROLE_USUARIO_MDFE_ENCERRAMENTO'] },
    { icon: 'mdi-percent', title: 'Tributação', routeName: 'Tributacao', permissoes: ['ROLE_CONTADOR'] },
    { icon: 'mdi-certificate', title: 'Certificados Digitais', routeName: 'CertificadosDigitais', permissoes: ['ROLE_USUARIO', 'ROLE_MODERADOR', 'ROLE_ADMIN', 'ROLE_USUARIO_MONITOR', 'ROLE_USUARIO_OPERADOR', 'ROLE_CONTADOR'] }
  ];
  
  export const baseApiUrl = process.env.VUE_APP_BASE_URL;
  export const AuthApiUrl = process.env.VUE_APP_AUTH_URL;
  export const MdfeApiUrl = process.env.VUE_APP_MDFE_ENGINE_URL;
  export const CteEngineApiUrl = process.env.VUE_APP_CTE_ENGINE_URL;
  export const CadastroApiUrl = process.env.VUE_APP_CADASTRO_URL;
  